import moment from "moment";
import Addons from "../addons/addons";
import parse from "html-react-parser";
import Orders from "../orders/orders";
import Events from "../events/events";
import Ticket from "../tickets/tickets";
import ProcessBar from "../../components/process-bar";
import useToast, {EToast} from "../../hooks/useToast";
import QuestionsInformation from "../questions/questions";
import ThankYou from "../../components/thank-you/thank-you";
import ButtonComponent from "../../components/button/button";
import TicketClose from "../../components/TicketClose/TicketClose";
import BillingInformation, {IError} from "../billing-info/billing-info";
import PaymentService from "../../components/payment-service/payment-service";
import handleServiceError, {convertPhoneNumber, fixDecimals,} from "../../utils/helper";
import {IAccount, IEventDetail, IRequestPurchaseTicket} from "../../apis/types";
import {useContext, useEffect, useMemo, useState} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import {initStepQuestion} from "../../data/initStepQuestion";
import {TicketContext} from "../../contexts/ticket-context";
import {PhoneCountry} from "../../constants/phone-country";
import {ThemeContext} from "../../contexts/theme-context";
import {EventContext} from "../../contexts/event-context";
import {validateEmail, validatePhone} from "./validate";
import {setEmailLocal} from "../../utils/localStorage";
import {StepData} from "../../constants/step-type";
import {requestPurchaseTicketFn} from "../../apis";
import {parsePhoneNumber} from "libphonenumber-js";
import {useLoading} from "../../hooks/useLoading";
import {ETheme} from "../../constants/theme";
import {useTranslation} from "react-i18next";

import "./content.scss";
import * as ct from "countries-and-timezones";

const initialStepData: StepData[] = Object.values(StepData).filter(step => step !== StepData.QUESTION && step !== StepData.ADDONS);

export default function Content() {
  const addToast = useToast();
  const location = useLocation();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const countryCode = ct.getTimezone(timeZone)?.countries[0];
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const { showLoading, hideLoading } = useLoading();
  const { loadedEvents } = useContext(EventContext);
  const { tickets, currency, setEvent, event, serviceCostMaxFee, getTicketByUrl } = useContext(TicketContext);
  const [step, setStep] = useState<number>(0);
  const [link, setLink] = useState<string>("");
  const [isQuestion, setIsQuestion] = useState<boolean>(false);
  const [stepQuestions, setStepQuestion] = useState(initStepQuestion);
  const [stepData, setStepData] = useState<string[]>(initialStepData);
  const [searchParams] = useSearchParams();
  const [isAddon, setIsAddon] = useState<boolean>(
    tickets.some((ticket) => ticket.isAddOn)
  );
  const [account, setAccount] = useState<IAccount>({ email: "", phone: "" });
  const [error, setError] = useState<IError>({
    email: "",
    phone: "",
  });


  const handleChangeValue = (
    blockIndex: number,
    itemIndex: number,
    value: any
  ) => {
    const newStepQuestions = [...stepQuestions];
    newStepQuestions[blockIndex][itemIndex]["answer"] = value;
    setStepQuestion(newStepQuestions);
  };

  const checkEventIsEnded = useMemo(() => {
    if (event) {
      const current = moment().valueOf();
      const endDate = moment(event.endDate).valueOf();
      if (current > endDate) return true;
    }
    return false;
  }, [event]);

  useEffect(() => {
    if (tickets) setIsAddon(tickets.some((ticket) => ticket.isAddOn));
    if (theme === ETheme.OR) setIsQuestion(true);

    if (tickets) {
      let newStepData = [...initialStepData];
      if (theme === ETheme.OR && tickets.some((ticket) => ticket.isAddOn)) {
        const elementsToInsert = [StepData.ADDONS, StepData.QUESTION];
        newStepData.splice(1, 0, ...elementsToInsert);
      } else if (theme === ETheme.OR && !tickets.some((ticket) => ticket.isAddOn)) {
        newStepData.splice(1, 0, StepData.QUESTION);
      } else if (theme !== ETheme.OR && tickets.some((ticket) => ticket.isAddOn)) {
        newStepData.splice(1, 0, StepData.ADDONS);
      }
      setStepData(newStepData);
    }
  }, [tickets, theme]);

  useEffect(() => {
    if (countryCode) {
      const phoneCountry = PhoneCountry.find(
        (item) => item.code === countryCode
      );
      phoneCountry && setAccount({ ...account, phone: phoneCountry.phone });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  const onChangeAccount = (acc: IAccount) => {
    setError({ email: "", phone: "" });
    setAccount(acc);
  };

  const renderLastStep = useMemo(() => {
    if (stepData[step] === StepData.PAYMENT_SERVICE) return <PaymentService />;
    if (stepData[step] === StepData.THANKYOU) return <ThankYou />;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, stepData, t]);

  const renderContent = useMemo(() => {
    switch (stepData[step]) {
      case StepData.TICKETS:
        return <Ticket />;
      case StepData.ADDONS:
        return <Addons />;
      case StepData.QUESTION:
        return (
          <QuestionsInformation
            stepQuestions={stepQuestions}
            handleChangeValueQuestion={handleChangeValue}
          />
        );
      case StepData.CHECKOUT:
        return (
          <>
            <BillingInformation
              error={error}
              account={account}
              setAccount={onChangeAccount}
            />
            <Orders />
          </>
        );
      case StepData.PAYMENT_SERVICE:
        return renderLastStep;
      case StepData.THANKYOU:
        return renderLastStep;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, account, error, t, isAddon, stepQuestions]);

  const renderTextBtn = useMemo(() => {
    const totalPay = tickets.reduce(
      (acc, item) => acc + item.order * item.price,
      0
    );
    const formatTotal = fixDecimals(totalPay * (1 + serviceCostMaxFee));

    if (stepData[step] === StepData.TICKETS) {
      if (stepData[step + 1] === StepData.ADDONS) {
        return t("btn.addons");
      } else if (stepData[step + 1] === StepData.QUESTION) {
        return t("btn.questions");
      } else if (stepData[step + 1] === StepData.CHECKOUT) {
        return t("btn.checkout");
      }
    }

    if (stepData[step] === StepData.ADDONS) {
      if (stepData[step + 1] === StepData.QUESTION) {
        return t("btn.questions");
      } else if (stepData[step + 1] === StepData.CHECKOUT) {
        return t("btn.checkout");
      }
    }
    if (stepData[step] === StepData.QUESTION) {
      return t("btn.checkout");
    }
    if (stepData[step] === StepData.CHECKOUT) {
      return parse(
        `${t(
          "btn.pay"
        )} <span className="concurrency">${currency}</span> ${formatTotal}`
      ) as string;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, tickets, t, isAddon]);

  const onChangeStep = (value?: number) => {
    const ticketSelected = tickets.find((item) => item.count > 0);
    if (value || value === 0) {
      if (stepData[value] !== StepData.TICKETS) {
        if (!ticketSelected)
          return addToast(EToast.ERROR, t("toast.validate.ticket"));
      }
      if (
        stepData[value] === StepData.CHECKOUT &&
        stepData[value - 1] === StepData.QUESTION
      ) {
        const hasUnansweredQuestions = customArrayQues()
          .filter(
            (item) =>
              item.question !==
              "Do you have any serious medical needs that we should know about?"
          )
          .some((obj) => !obj.answer);
        if (hasUnansweredQuestions)
          return addToast(
            EToast.ERROR,
            "Please complete your answers before checking out"
          );
      }
      setError({ email: "", phone: "" });
      setStep(value);
      return;
    }
    const nextStep = step + 1;

    if (stepData[nextStep] === StepData.PAYMENT_SERVICE) {
      const isValidate = onValidateAccount();
      if (isValidate) {
        onLocketTicket();
      }
      return;
    }
    setError({ email: "", phone: "" });
    setStep(nextStep);
  };

  const onValidateAccount = () => {
    if (!account.email && !account.phone) {
      setError({ email: "", phone: t("toast.validate.require") });
      return false;
    }
    if (!account.email) {
      setError({
        phone: "",
        email: t("toast.validate.emailRequire"),
      });
      return false;
    }
    if (!account.phone) {
      setError({
        phone: t("toast.validate.phoneRequire"),
        email: "",
      });
      return false;
    }
    if (account.email) {
      const test = validateEmail(account.email);
      if (!test) {
        setError({
          phone: "",
          email: t("toast.validate.inValidEmail"),
        });
        return false;
      }
    }
    if (account.phone) {
      const phoneNumber = convertPhoneNumber(account.phone);
      const test = validatePhone(phoneNumber);
      if (!test) {
        setError({
          phone: t("toast.validate.inValidPhone"),
          email: "",
        });
        return false;
      }
      try {
        parsePhoneNumber(account.phone);
      } catch (error: any) {
        setError({
          phone: t(
            `${error.message === "INVALID_COUNTRY"
              ? t("toast.validate.inValidCountry")
              : t("toast.validate.inValidPhone")
            }`
          ),
          email: "",
        });
        return false;
      }
    }
    return true;
  };

  const customArrayQues = () => {
    let outputArray = initStepQuestion
      .flat()
      .filter((item) => item.type !== "text");
    let answersYesNo = outputArray.find(
      (item) => item.question === "Are you sharing a tent?"
    )?.answer;

    let outputQA = outputArray;
    if (answersYesNo === "No") {
      outputQA = outputQA.filter(
        (item) =>
          item.question !== "Is it a partner or friend?" &&
          item.question !== "What is their name?"
      );
    }
    const payloadArrayQA = outputQA.map((questionObj) => {
      const { question, answer } = questionObj;
      return {
        question,
        answer,
      };
    });

    return payloadArrayQA;
  };

  const onLocketTicket = async () => {
    const ticketInfoList = tickets
      .filter((item) => item.order > 0)
      .map((item) => ({
        ticketDefinitionId: item._id,
        amount: item.order,
      }));

    const parsePhone = parsePhoneNumber(account.phone);
    const body: IRequestPurchaseTicket = {
      tickets: ticketInfoList,
      email: account.email,
      phone: parsePhone.number,
      eventId: event._id,
      answers: isQuestion ? customArrayQues() : [],
    };
    setEmailLocal(account.email);
    try {
      showLoading();
      const data = await requestPurchaseTicketFn(body);
      setLink(data.url);
    } catch (error) {
      handleServiceError(error);
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    if (event?._id) setStep(0)
  }, [event])

  const disableBtn = useMemo(() => {
    const ticketSelect = tickets.find((item) => item.count > 0);
    if (step === 0) {
      if (!ticketSelect) return true;
    }
    if (stepData[step] === StepData.QUESTION) {
      const hasUnansweredQuestions = customArrayQues()
        .filter(
          (item) =>
            item.question !==
            "Do you have any serious medical needs that we should know about?"
        )
        .some((obj) => !obj.answer);
      if (hasUnansweredQuestions) return true;
    }
    return false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets, step, stepQuestions, isQuestion]);

  useEffect(() => {
    if (link) {
      window.location.assign(link);
      setStep(stepData.indexOf(StepData.PAYMENT_SERVICE));
    }
    setLink("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  useEffect(() => {
    const paramsAsObject = Object.fromEntries([...(searchParams as any)]);
    if (paramsAsObject.transactionId) {
      // setStep(isAddon ? 4 : 3);
      setStep(stepData.indexOf(StepData.THANKYOU));
    }
  }, [searchParams, theme, stepData]);

  useEffect(() => {
    const orderTicket = tickets.find((item) => item.order > 0);
    const shouldResetStep = !orderTicket && stepData[step] === StepData.CHECKOUT;
    if (shouldResetStep) {
      setStep(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets]);

  useEffect(() => {
    const eventUrl = location.pathname.split("/")[1];
    if (eventUrl !== '') {
      getTicketByUrl('https://test.chi.tickets/' + eventUrl);
      console.log("checkEventIsEnded: ", !checkEventIsEnded);
      console.log("loadedEvents: ", loadedEvents);
      console.log("eventId: ", event?._id);
    }
    else setEvent({} as IEventDetail);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    console.log("event ID: ", event?._id);
  }, [event])

  return (
    <div
      className={`content ${(stepData[step] === StepData.PAYMENT_SERVICE ||
        stepData[step] === StepData.THANKYOU || checkEventIsEnded) &&
        "border-top"
        }`}
    >
      {(stepData[step] !== StepData.PAYMENT_SERVICE && stepData[step] !== StepData.THANKYOU && !checkEventIsEnded && loadedEvents && event?._id) && (
        <div className="content-progress">
          <ProcessBar
            step={step}
            setStep={onChangeStep}
            isAddon={isAddon}
            stepData={stepData}
          />
        </div>
      )}
      <div
        className="content-title"
        style={{
          padding: stepData[step] === StepData.PAYMENT_SERVICE ? "24px 0 0 0" : loadedEvents ? "16px 0px 20px" : "",
        }}
      >
        {loadedEvents && !event?._id ? <Events /> : !checkEventIsEnded ? <> {renderContent}</> : <TicketClose />}
      </div>
      {stepData[step] !== StepData.PAYMENT_SERVICE && stepData[step] !== StepData.THANKYOU && !checkEventIsEnded && loadedEvents && event?._id && (
        <ButtonComponent
          disabled={disableBtn}
          text={renderTextBtn as string}
          onClick={() => onChangeStep()}
        />
      )}
    </div>
  );
}