import React, {
  useState,
  createContext,
  PropsWithChildren,
  useEffect,
} from "react";
import { ETheme } from "../constants/theme";
import { getThemeLocal, setThemeLocal } from "../utils/localStorage";
import {fetchEventsByDate, fetchTicketEventByUrl} from "../apis";
import { useSearchParams } from "react-router-dom";
import { url } from "inspector";

export interface IThemeContext {
  theme: ETheme;
  onChangeTheme: () => void;
  name: string;
  description: string;
  url: string;
}

export const ThemeContext = createContext<IThemeContext>({
  theme: ETheme.DARK,
  onChangeTheme: () => { },
  name: "EventCHI",
  description: "Website created by EventCHI",
  url: ""
});

const ThemeProvider = (props: PropsWithChildren) => {
  const themeDefault = ETheme.OR;
  const [theme, setTheme] = useState<any>("");
  const [name, setName] = useState<string>("EventCHI");
  const [description, setDescription] = useState<string>("Website created with EventCHI ");
  const [url, setUrl] = useState<string>("");
  const [searchParams] = useSearchParams();

  const getFullUrl = () => {
    let url = window.location.href;
    const obj = new URL(url);
    obj.search = "";
    obj.hash = "";
    url = obj.toString();
    const lastString = url.slice(-1);
    if (lastString === "/") url = url.substring(0, url.length - 1);
    setUrl(url);
    return url;
  };

  const getUpcomingParams = (page = 1) => {
    return {
      date: "anytime",
      page: page,
      limit: 10
    }
  }

  const getInfoByUrl = async () => {
    const url = getFullUrl();
    
    if (url.split("/")[3]) {
      fetchTicketEventByUrl(url)
        .then((res) => {
          if (res) {
            if (res.theme) setTheme(res.theme || ETheme.DARK);
  
            if (res.event) {
              if (res.event.description) setDescription(res.event.description);
              if (res.event.name) setName(res.event.name);
            }
          }
        })
        .catch(async error => {
          if (error.response.data.Error === "Event not found ") {
            setTheme(ETheme.DARK);
          }
        });
    } else setTheme(ETheme.DARK);
};

  useEffect(() => {
    const paramsAsObject = Object.fromEntries([...(searchParams as any)]);
    const themeLocalStorage = getThemeLocal();
    if (paramsAsObject.transactionId) {
      if (paramsAsObject.theme) {
        setTheme(paramsAsObject.theme);
      } else {
        setTheme(themeLocalStorage || 'DARK');
      }
    } else {
      getInfoByUrl();
    }
    // TransactionSuccess();
  }, [searchParams]);

  useEffect(() => {
    document.documentElement.setAttribute(
      "data-theme",
      theme === ETheme.DARK ? "dark" : theme
    );
    setThemeLocal(theme);
    if (theme === ETheme.DARK)
      document.documentElement.classList.add('cc--darkmode');
  }, [theme, searchParams]);

  const onChangeTheme = () => {
    const newTheme = theme === ETheme.DARK ? ETheme.LIGHT : ETheme.DARK;
    setTheme(newTheme);
    document.documentElement.setAttribute(
      "data-theme",
      newTheme === ETheme.DARK ? "dark" : "light"
    );
    setThemeLocal(newTheme);
  };

  const contextValue = {
    theme,
    onChangeTheme,
    name,
    description,
    url
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
